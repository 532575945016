import './Services.css';
import { Link } from 'react-router-dom';
import { IoArrowForwardOutline } from 'react-icons/io5';
import main1 from '../../../img/Services/foto1.png';
// import main2 from '../../../img/Services/maintenance.png';
import main3 from '../../../img/Services/pedal.png';
import main4 from '../../../img/Services/foto4.png';
import main5 from '../../../img/Services/foto5.png';
import main6 from '../../../img/Services/brake.png';
import main7 from '../../../img/Services/clean-car.png';
import aboutservice from '../../../img/Services/aboutservice.jpg';

function AboutService() {
  return (
    <div className="about-service">
      <div className="main__service-title">
        <span>o serwisie</span>
      </div>
      <div className="about-service_img_text">
        <img src={aboutservice} alt="service" className="about-service_img" />
        <div className="about-service_text">
          <h2>"Rompar Serwise" - wszystko, czego potrzebuje Twój samochód </h2>
          <p>
            Przecież tutaj stworzyliśmy miejsce, w którym szybko i jakościowo
            przeprowadzamy naprawy odtwórcze i zapobiegawcze samochodów
            osobowych wszystkich marek.
          </p>
          <p>
            Nowoczesny sprzęt diagnostyczny i naprawczy, gwarancja na wykonaną
            pracę, szeroka oferta części samochodowych, zgodność ze standardami
            technologicznymi to nie wszystkie atuty, którymi cieszą się nasi
            Klienci.
          </p>
          <div className="about-servise_link">
            <Link to="/about">Szczegółowo...</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
export function ServicesOnly() {
  return (
    <div className="main__services-one">
      <div className="main__services-item">
        <div className="main__services-img-price">
          <div className="main__services-img">
            <img src={main1} alt="photo1" />
          </div>
        </div>
        <div className="main__services-name-link">
          <div className="main__services-name">
            <span>wymiana oleju</span>
          </div>
          <div className="main__services-link">
            <Link to="/changeoil" title="changeoil">
              <IoArrowForwardOutline className="service_icon" />
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="main__services-item">
        <div className="main__services-img-price">
          <div className="main__services-img">
            <img src={main2} alt="photo1" />
          </div>
        </div>
        <div className="main__services-name-link">
          <div className="main__services-name">
            <span>Konserwacja</span>
          </div>
          <div className="main__services-link">
            <Link to="/diagnostika">
              <IoArrowForwardOutline className="service_icon" />
            </Link>
          </div>
        </div>
      </div> */}
      <div className="main__services-item">
        <div className="main__services-img-price">
          <div className="main__services-img">
            <img src={main3} alt="photo1" />
          </div>
        </div>
        <div className="main__services-name-link">
          <div className="main__services-name">
            <span>Wymiana sprzęgiel</span>
          </div>
          <div className="main__services-link">
            <Link to="/sprzegiel">
              <IoArrowForwardOutline className="service_icon" />
            </Link>
          </div>
        </div>
      </div>
      <div className="main__services-item">
        <div className="main__services-img-price">
          <div className="main__services-img">
            <img src={main4} alt="photo1" />
          </div>
        </div>
        <div className="main__services-name-link">
          <div className="main__services-name">
            <span>naprawa podwozia</span>
          </div>
          <div className="main__services-link">
            <Link to="/naparawapodwozia">
              <IoArrowForwardOutline className="service_icon" />
            </Link>
          </div>
        </div>
      </div>
      <div className="main__services-item">
        <div className="main__services-img-price">
          <div className="main__services-img">
            <img src={main5} alt="photo1" />
          </div>
        </div>
        <div className="main__services-name-link">
          <div className="main__services-name">
            <span>diagnostyka samochodów</span>
          </div>
          <div className="main__services-link">
            <Link to="/diagnostika">
              <IoArrowForwardOutline className="service_icon" />
            </Link>
          </div>
        </div>
      </div>
      <div className="main__services-item">
        <div className="main__services-img-price">
          <div className="main__services-img">
            <img src={main6} alt="photo1" />
          </div>
        </div>

        <div className="main__services-name-link">
          <div className="main__services-name">
            <span> Wymiana uklady hamulcowe</span>
          </div>
          <div className="main__services-link">
            <Link to="/hamulcowe">
              <IoArrowForwardOutline className="service_icon" />
            </Link>
          </div>
        </div>
      </div>
      <div className="main__services-item">
        <div className="main__services-img-price">
          <div className="main__services-img car-clean-img">
            <img src={main7} alt="photo1" />
          </div>
        </div>

        <div className="main__services-name-link">
          <div className="main__services-name car-clean-name">
            <span> Pranie tapicerki samochodowej</span>
          </div>
        </div>
      </div>
    </div>
  );
}
function Services() {
  return (
    <>
      <div className="main__service">
        <div className="main__service-title">
          <span>Usługi</span>
        </div>

        <ServicesOnly />
        <AboutService />
      </div>
    </>
  );
}
export default Services;
