import './ChangeOil.css';
import Header from '../../1_Header/Container/Header';
import changeoil from '../../../img/changeoil/changeoil.jpg';
import { FormaZayavkaContact } from '../../Forma/Forma_zayavka';
import Footer from '../../3_Footer/Footer';
import { ReactComponent as Foto1 } from '../../../img/changeoil/foto1.svg';
import { ReactComponent as Foto2 } from '../../../img/changeoil/foto2.svg';
import { ReactComponent as Foto3 } from '../../../img/changeoil/foto3.svg';
import { ReactComponent as Foto4 } from '../../../img/changeoil/foto4.svg';
import { ReactComponent as Foto5 } from '../../../img/changeoil/foto5.svg';
import WhatsApp from '../../../WhatsUp/WhatsappLink';

function ChangeOil() {
  const scrolldown = () => {
    // const scrollY = document.querySelector('body').scrollHeight;
    const scrollY = document.querySelector('body').clientHeight;

    window.scroll({ top: scrollY - 1000, behavior: 'smooth' });
  };
  return (
    <>
      <div className="body_wrapper">
        <Header />
        <div className="changeoil">
          <h1>Wymiana oleju</h1>
          <div className="changeoil__img-text">
            <img src={changeoil} alt="changeoil" className="changeoil-img" />
            <div className="changeoil-text">
              <p>
                Podczas pracy silnika paliwo, wióry metalowe i inne
                zanieczyszczenia mogą przedostać się do materiału smarującego,
                co prowadzi do pogorszenia ogólnego stanu samochodu. Stary olej
                nie może spełniać swoich funkcji, dlatego konieczna jest jego
                terminowa wymiana.
              </p>
              <p>
                Każdy producent tej lub innej marki samochodu ma swoje własne
                wymagania dotyczące jakości oleju, jego dat ważności i cech jego
                wymiany. Jednocześnie wielu kierowców samodzielnie przeprowadza
                procedurę wymiany oleju, nie biorąc pod uwagę zalecanych
                wymagań. W rezultacie stają w obliczu pojawienia się usterek,
                przed którymi chcieli chronić swój samochód.
              </p>
              <p>
                Specjaliści "Rampar Serwise" dokonują indywidualnego doboru
                płynów smarowych, filtrów i środków czyszczących. Nie ryzykuj i
                zwróć się do profesjonalistów! Skontaktuj się z naszymi
                specjalistami serwisowymi, aby uzyskać optymalne czasy wymiany
                oleju.
              </p>
              <div className="changeoil-zayavka">
                <button
                  onClick={scrolldown}
                  type="button"
                  className="changeoil-link"
                >
                  Zarejestruj się w serwisie
                </button>
              </div>
            </div>
          </div>
          <h2 className="changeoil-h2">Usługa wymiany oleju obejmuje:</h2>
          <div className="changeoil__services">
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto1 /> <h3>Wymiana oleju silnikowego</h3>
              </div>
              <p>
                Wymiana oleju odbywa się wyłącznie przy wymianie filtrów z
                wypompowaniem smaru i bez. I choć nowe samochody znacznie
                rzadziej wymagają wymiany materiałów, terminowe zastosowanie
                gwarantuje lepsze chłodzenie zespołów roboczych, zmniejszenie
                tarcia i działanie antykorozyjne, redukcję sadzy i osadów.
              </p>
            </div>
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto2 /> <h3> wymiana oleju w automatycznej skrzyni biegów</h3>
              </div>
              <p>
                Po przeprowadzeniu diagnostyki komputerowej i sprawdzeniu
                obecności wycieków oleju specjalista oferuje jeden z rodzajów
                wymiany: sprzętową lub częściową. Jednocześnie zalecamy
                częściową wymianę oleju co 10 000 km lub w razie potrzeby,
                stwierdzonej podczas przeglądu technicznego.
              </p>
            </div>
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto3 /> <h3>wymiana oleju w manualnej skrzyni biegów</h3>
              </div>
              <p>
                Choć wielu kierowców zaniedbuje tę usługę i uważa ją za
                opcjonalną, zalecamy wymianę oleju w manualnej skrzyni biegów co
                60 000 km. Olej starzeje się i brudzi, dlatego nie może
                skutecznie spełniać funkcji chłodzenia i zmniejszania tarcia
                części.
              </p>
            </div>
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto4 /> <h3>wymiana oleju w skrzyni biegów</h3>
              </div>
              <p>
                Konieczna jest terminowa wymiana płynu olejowego w skrzyni
                biegów, aby poprawić interakcję części urządzenia i wydłużyć ich
                okres eksploatacji. Zazwyczaj elementy samochodowe psują się na
                skutek zużycia, które jest spowodowane złym spełnianiem swoich
                funkcji na starym oleju.
              </p>
            </div>
            <div className="changeoil__item">
              <div className="changeoil__item-title">
                <Foto5 /> <h3>wymiana oleju w wariatorze</h3>
              </div>
              <p>
                Kierowcy wiedzą, że ta skrzynia biegów jest niezwykle wrażliwa
                na obciążenia i jakość oleju. W rzeczywistości oznacza to, że
                samochody z wariatorami nie są przeznaczone do agresywnej jazdy
                i trudnych warunków pracy. Dlatego profesjonalizm specjalisty,
                stosowanie sprawdzonych płynów i terminowe stosowanie odgrywają
                tutaj decydującą rolę w ogólnym stanie zdrowia samochodu.
              </p>
            </div>
          </div>
          <div className="formazayavka">
            <FormaZayavkaContact />
          </div>
        </div>

        <Footer />
        <WhatsApp />
      </div>
    </>
  );
}

export default ChangeOil;
