import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MyPhone from '../../UI/MyPhone/MyPhone';
import './Footer.css';
import MyEmail from '../../UI/MyEmail/MyEmail';
import MySocialLinks from '../../UI/MySocialLinks/MySocialLinks';

function Uslugi() {
  return (
    <div className="uslugi">
      <h3>Diagnostyka samochodów</h3>
      <h3>Naprawa podwozia</h3>
      <h3>Wymiana uklady hamulcowe</h3>
      <h3>Konserwacja</h3>
      <h3>Wymiana oleju</h3>
      <h3>Wymiana sprzęgiel</h3>
    </div>
  );
}

function FooterMenu() {
  const [isScrollUp, setScrollUp] = useState(true);

  useEffect(() => {
    if (isScrollUp) {
      window.scroll({ top: 0, behavior: 'smooth' });
      setScrollUp(false);
    }
  }, [isScrollUp]);

  return (
    <nav className="footer-menu">
      <ul className="footer__menu__list">
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/"
            className="footer__menu__link"
          >
            Główny
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/about"
            className="footer__menu__link"
          >
            O nas
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/price"
            className="footer__menu__link"
          >
            Usługi
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/article1"
            className="footer__menu__link"
          >
            Blog
          </Link>
        </li>
        <li className="footer__menu__item">
          <Link
            onClick={() => setScrollUp(true)}
            to="/contact"
            className="footer__menu__link"
          >
            Kontakt
          </Link>
        </li>
      </ul>
    </nav>
  );
}

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container footer__container">
          <div className="footer__main">
            <div className="footer__menu">
              <Uslugi />
              <FooterMenu />
            </div>

            <div className="footer__phone-social-email">
              <MyPhone
                className="footer__phone"
                classnamelink="footer__phone-link"
              />
              <MyEmail classnamelink="footer__email">
                warsztat.rompar@op.pl
              </MyEmail>
              <MySocialLinks classnames="footer__social " />
            </div>
          </div>
        </div>
        <div className="footer__down">
          <h3> Serwis samochodowy | Rompar serwis | 2025 </h3>
        </div>
      </div>
    </>
  );
}
export default Footer;
